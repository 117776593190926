<template>
  <div>
    <div class="placeholder-login">
      <div class="pane">
        <div class="header">Micron Kit</div>
        <div class="app_shoutout">
          Driving the future of animal healthcare through targeted treatment.
        </div>
        <div class="image-holder">
          <img src="@/assets/our_app.svg" alt="Micron Kit App" />
        </div>
      </div>
      <div class="pane">
        <div class="loginContainer">
          <div class="logoblk">
            <img src="@/assets/micron_teal.png" alt="Micron Logo" />
          </div>
          <h3>Forgot your password?</h3>
          <div class="error" v-for="error in ErrorMsg" :key="error">
            {{ error }}
          </div>
          <div class="success" v-for="message in messages" :key="message">
            {{ message }}
          </div>
          <form action="#" class="loginForm" v-on:submit.prevent="ForgotPassword">
            <label for="email">Email Address</label>
            <input type="email" class="input" placeholder="example@example.com" v-model="EmailAddress" />
            <br />
            <br />
            <input type="submit" class="btn-login btn-teal" value="Start Recovery" style="width: 100%"
              :disabled="disableBtn" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "Login",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      EmailAddress: "",
      ErrorMsg: [],
      messages: [],
      disableBtn: false,
    };
  },
  methods: {
    ForgotPassword() {
      this.disableBtn = true;
      this.store
        .dispatch("User/ForgotPassword", { email: this.EmailAddress })
        .then((result) => {
          this.messages = result.messages;
        })
        .catch((err) => {
          this.disableBtn = false;
          console.log(err);
          this.ErrorMsg = err.messages;
        });
    },
  },
  created() {
    this.store
      .dispatch("User/CheckAuth")
      .then((data) => {
        console.log(data);
        this.$router.push({ name: "Home" });
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style>
.placeholder-login {
  font-family: Arial, sans-serif;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  background: #ffffff;
}

.pane:first-child {
  position: relative;
  flex: 0 0 44%;
  width: 44%;
  background: #35bba4;
  color: #fff;
}

.pane:nth-child(2) {
  flex: 0 0 56%;
  width: 56%;
  align-self: center;
}

.header {
  height: auto;
  width: 100%;
  padding: 20px;
  font-weight: 700;
  font-size: 6em;
  text-align: center;
}

.app_shoutout {
  height: auto;
  width: 100%;
  padding: 10px;
  font-weight: 500;
  font-size: 30px;
}

.image-holder img {
  height: auto;
  width: 70% !important;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  position: relative;
}

.loginContainer {
  width: 50%;
  height: auto;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
}

.logoblk img {
  height: auto;
  width: auto;
  max-width: 200px;
  box-sizing: border-box;
}

.loginForm {
  text-transform: uppercase;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  position: relative;
}

.loginForm label {
  display: block;
  font-size: 0.8em;
  color: #4d4d4d;
  padding: 10px;
}

.loginForm .input {
  height: 50px;
  width: 100%;
  font-size: 27px;
  color: #4d4d4d !important;
  text-align: center;
  display: block;
  background: #fff !important;
  outline: 0;
  border: 1px solid #c8ced5;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 1px;
  box-sizing: border-box;
}

.loginForm .input:hover,
.loginForm .input:focus {
  border-color: #35bba4;
}

.btn-login {
  height: 40px;
  outline: 0;
  border: none;
  background: inherit;
  line-height: 40px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  font-size: 17px;
  display: block;
  box-sizing: border-box;
  text-align: center;
  border-radius: 5px;
}

.btn-teal {
  background: #35bba4;
  color: #fff;
}

.btn-teal:hover {
  background: #31b39d;
  color: #fff;
}

.error {
  height: auto;
  width: 100%;
  background: #e34343;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #c52f2f;
}

/* If screen 900px or less. */
@media only screen and (max-width: 910px) {
  .pane:first-child {
    display: none;
  }

  .pane:nth-child(2) {
    flex: 0 0 100%;
    width: 100%;
    align-self: center;
  }

  .loginContainer {
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
  }
}
</style>